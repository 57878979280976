<template>

  <div class="desktopLayoutClass" id="heatMapWrapper" >
    <div >
      <NavBar style="max-height=50px;" :username="customer_name" :pageName="pageName" class="desktopLayoutClass"></NavBar>
      <v-app style="background-color: #f5faff;" class="Maindiv" id="heatMap">
        <!-- Dialog Start -->
        <div>
          <v-row justify="center">
            <v-dialog v-model="auth_error_enable" persistent max-width="290">
              <v-card>
                <v-card-title style="word-break:normal">
                  {{$t("Warning_Message.Session_Expired_Please_Login_Again")}}
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" depressed @click="closeDialogUnauthorized">
                    {{$t("Warning_Message.Login")}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
        <div>
          <v-row justify="center">
            <v-dialog v-model="error_enable" persistent max-width="290">
              <v-card>
                <v-card-title style="word-break:normal">
                    {{$t("Warning_Message.Something_Went_Wrong")}}
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn depressed
                      color="primary" @click="closeDialogError">
                     {{$t("Warning_Message.Ok")}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
        <div>
          <v-row justify="center">
            <v-dialog v-model="region_block" persistent max-width="290">
              <v-card>
                <v-card-title style="word-break:normal">
                    <span class="regionLockClass">{{$t("Warning_Message.Region_Block")}}</span>
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn depressed
                      color="primary" @click="closeMyMedicine" class="regionLockClass">
                     {{$t("Warning_Message.Ok")}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>

        <div>
          <v-row justify="center">
            <v-dialog v-model="tcConsentModal" persistent max-width="290">
              <v-card>
                <v-card-title style="word-break:normal">
                    <p style="font-size:15px; margin-bottom:0px !important">
                      {{ $t("Warning_Message.First_Consent_Request_Part1") }}
                      <span @click="redirectToTermsAndCondition" style="color:#1467BF;">{{ $t("Warning_Message.Terms_And_Condition_Link") }}</span>
                      {{ $t("Warning_Message.First_Consent_Request_Part2") }}
                    </p>
                </v-card-title>
                <v-card-actions>
                  <!-- <v-spacer></v-spacer> -->
                  <div style="width:100%">
                    <!-- <div>
                  <v-btn depressed
                    style="font-size:14px"
                    color="warning" @click="closeTcConsent">
                     {{ $t("Warning_Message.Deny") }}
                  </v-btn>
                  </div> -->
                  <div v-if="isApp">
                  <v-btn
                  depressed
                  style="font-size:14px;"
                  color="primary"
                  class="tcconcentClass"
                  :disabled= tcConcentBtn
                  width="90%"
                  @click="tcAccepted">
                    {{ $t("Warning_Message.Log_In_With_KBZpay") }}
                  </v-btn>
                  </div>
                  <div v-else>
                  <v-btn
                  depressed
                  style="font-size:14px; "
                  color="primary"
                  class="tcconcentClass"
                  :disabled= tcConcentBtn
                  width="90%"
                  @click="tcAccepted">
                    {{ $t("Warning_Message.Log_In") }}
                  </v-btn>
                  </div>
                  </div>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>

        <div>
          <v-row justify="center">
            <v-dialog v-model="tcConsentAssistModal" persistent max-width="290">
              <v-card>
                <v-card-title style="word-break:normal">
                   <p  style="font-size:15px">
                     {{ $t("Warning_Message.Denied_Popup_Part1") }}
                     <span @click="redirectToTermsAndCondition" style="color:#1467BF;">{{ $t("Warning_Message.Terms_And_Condition_Link") }}</span>
                     {{ $t("Warning_Message.Denied_Popup_Part2") }}
                   </p>
                </v-card-title>
                <v-card-actions>
                  <div style="display:flex; flex-direction:row; justify-content: flex-end;width:100%">
                  <div>
                  <v-btn depressed color="primary" @click="closeTcConsentAssist">
                     {{$t("Warning_Message.Ok")}}
                  </v-btn>
                  </div>
                  </div>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>

        <div>
          <v-row justify="center">
            <v-dialog v-model="tcConsentChangeModal" persistent max-width="290">
              <v-card>
                <v-card-title style="word-break:normal">
                   <p  style="font-size:15px">
                     {{ $t("Warning_Message.Updated_Consent_Part1") }}
                     <span @click="redirectToTermsAndCondition" style="color:#1467BF;">{{ $t("Warning_Message.Terms_And_Condition_Link") }}</span>
                     {{ $t("Warning_Message.Updated_Consent_Part2") }}
                    </p>
                </v-card-title>
                <v-card-actions>
                  <div style="display:flex; flex-direction:row; justify-content: space-between;width:100%">
                    <div>
                  <v-btn depressed color="warning" @click="closeTcConsentChanged">
                     {{ $t("Warning_Message.Deny") }}
                  </v-btn>
                  </div>
                  <div>
                  <v-btn depressed color="primary" @click="ChangedTCAccepted">
                    {{ $t("Warning_Message.Accept") }}
                  </v-btn>
                  </div>
                  </div>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>

        <div>
          <v-row justify="center">
            <v-dialog v-model="error_alert" persistent max-width="290">
              <v-card>
                <v-card-title style="word-break:normal">
                    {{ $t("Warning_Message.Restart_App") }}
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn depressed color="primary" @click="closeMyMedicine">
                     {{$t("Warning_Message.Ok")}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>

        <div>
          <v-row justify="center">
            <v-dialog v-model="trust_alert" persistent max-width="290">
              <v-card>
                <v-card-title style="word-break:normal">
                    {{ $t("Warning_Message.Only_Trust") }}
                </v-card-title>
                <v-card-actions>
                  <div style="display:flex; flex-direction:row-reverse;justify-content: space-between; width:100%">
                    <div>
                      <v-btn @click="redirectToKBZPayUpgrade" depressed color="primary" >
                        {{ $t("Warning_Message.Upgrade") }}
                      </v-btn>
                    </div>
                    <div>
                      <v-btn @click="closeMyMedicine" depressed color="error" >
                        {{ $t("Warning_Message.Cancel") }}
                      </v-btn>
                    </div>
                  </div>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>

        <!-- Dialog for Videocall test Starts -->
        <!-- commented for hide verify devices  -->
        <!-- <div>
        <v-row justify="center">
            <v-dialog v-model="videoCallTestModal" persistent max-width="300px">
              <v-card>
                  <div class="dialogClass">
                      <div>
                          <img style="margin:10px" src="https://d3vsnl8idgwrlw.cloudfront.net/WarningDialog.svg"/>
                      </div>
                      <div>
                          <p>{{ $t("Customer.Home.Test_Prompt") }}</p>
                      </div>
                      <div class="mainBtnClass">
                        <div>
                          <v-btn  class="btnClass" outlined color="white" width="110px" @click="closeDialog">
                           {{ $t("Customer.Home.Cancel") }}
                          </v-btn>
                        </div>
                        <div>
                          <v-btn color="primary" width="110px" @click="redirectToVideoCallTestPage">
                            {{ $t("Customer.Home.Verify_Now") }}
                          </v-btn>
                        </div>
                      </div>
                  </div>
              </v-card>
            </v-dialog>
          </v-row>
        </div> -->
        <!-- Dilog for VideoCall test Ends -->

        <!-- app version modal start -->
        <div>
  				<v-row justify="center">
  					<v-dialog v-model="appLevelCheckModal" persistent max-width="290">
  					<v-card>
  						<v-card-title style="word-break:normal">
              <p>{{ $t("Warning_Message.AppUpdatePromptVideoCall") }}</p>
  						</v-card-title>
  						<v-card-actions>
  						<v-spacer></v-spacer>
  						<v-btn color="primary" depressed @click="closeAppLevelCheckModal">
  							{{$t("Warning_Message.Update")}}
  						</v-btn>
  						</v-card-actions>
  					</v-card>
  					</v-dialog>
  				</v-row>
  			</div>
        <!-- app version modal ends -->
        <!-- Dialog Close -->
        <div class="Maindiv" style="margin: 6px;padding-bottom: 10px;" align="center">
          <div style="height:150px">
          <banner  class="bannerClass" style="border-radius:8px!important; margin-top:10px "></banner>
          </div>
          <div v-if="!isLoadingAppointment">
            <div v-if="appointment_list.length > 0">
              <div align="left" class="upcoming">
                <h4 class="upcomingClass" style="margin-left:2%;">{{ $t("Customer.Home.Upcoming_Consultations") }}</h4>
                <div class="spacing">
                  <v-carousel hide-delimiters
                    :continuous="true"
                    :cycle="true"
                    :show-arrows="false"
                    style="margin:-1px !important; width:100% !important;"
                    height="200"
                    >
                    <v-carousel-item
                      v-for="(appointment, i) in appointment_list"  :key="i"
                      >
                      <v-sheet
                        height="80%"
                        style="background-color: #f5faff;"
                        >
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center"
                          >
                          <v-card class="HomeCardClass" style="width:90%; height:auto; border-radius: 8px;margin-top: 20px;padding:10px;">
                            <v-row no-gutters style="margin-top: 10px;">
                              <div style="width: 50%">
                                <v-row no-gutters>
                                  <v-col  class="homeUserPngClass" cols="2" sm="2">
                                    <img src="https://img.icons8.com/color/50/000000/user.png"/>
                                  </v-col>
                                  <!-- <v-col  cols="1" sm="1">
                                  </v-col> -->
                                  <v-col  cols="10" sm="10">
                                    <div class="homeNameCardCalss" style="font-weight: 600;font-size:.9em; margin-top:16px;padding-left: 10px;">
                                      {{appointment.booked_for_name}}
                                    </div>
                                  </v-col>
                                </v-row>
                              </div>
                              <div style="width: 50%" align="right">
                                <div  class="homeBtnClass" align="center" style="padding-top:10px;">
                                  <v-btn v-if="appointment.isLinkActive"
                                    depressed
                                    style="color:white;font-weight: 400;padding:0px 12px; background-color:#27AE60;"
                                    @click="redirectToCallpage(appointment._id)"
                                    >
                                    <v-icon left>
                                      mdi-video
                                    </v-icon>
                                    {{ $t("Customer.Home.Join") }}
                                  </v-btn>
                                  <v-btn v-else
                                    depressed
                                    class="btnHomeClass"
                                    color="primary"
                                    style="color:white;font-weight: 400;padding:0px 12px;"
                                    @click="redirectToViewAppointment(appointment._id)"
                                    >
                                    {{ $t("Customer.Home.View") }}
                                  </v-btn>
                                </div>
                              </div>
                            </v-row>

                            <v-row no-gutters style="margin-left: 10px; margin-top:10px">
                              <div  style="display: flex; flex-direction: row; width: 100%">
                                <div style="width: 100%">
                                  <div style="display: flex; flex-direction: row; width: 100%">
                                    <div class="dateClass1" style="width: 20%">
                                      <img src="https://d3vsnl8idgwrlw.cloudfront.net/calendar.svg"/>
                                    </div>
                                    <div  style="width: 100%;font-size:14px; margin-top:2px">
                                      <!-- {{ $t("Customer.Home.Date") }} -->
                                       <DateRendered  :dateVal="appointment.date_of_appointment" > </DateRendered>
                                    </div>
                                  </div>
                                </div>
                                <div style="width: 100%">
                                  <div  style="display: flex; flex-direction: row; width: 100%">
                                    <div style="width: 20%" class="timeClass">
                                      <img src="https://d3vsnl8idgwrlw.cloudfront.net/clock.svg"/>
                                    </div>
                                    <div style="width: 100%;font-size:14px;margin-top:2px;letter-spacing:-1px">
                                      <!-- {{ $t("Customer.Home.Time_Slot") }} -->
                                        <TimeRender :timeSlot="appointment.time_slot"> </TimeRender>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- <div style="display: flex; flex-direction:  row; width: 100%;padding-bottom: 20px;">
                                <div  class="dateClass" style="width: 50%; font-size:0.9em">
                                  <DateRendered  :dateVal="appointment.date_of_appointment" > </DateRendered>
                                </div>
                                <div  class="timeClass" style="width: 50%; font-size:0.9em">
                                  <TimeRender :timeSlot="appointment.time_slot"> </TimeRender>
                                </div>
                              </div> -->
                            </v-row>
                            <v-row>
                              <div class="notificationBoxClass" style="margin-top: 0px;
                                background-color: #fecdbe;
                                width: 100%;
                                border-bottom-right-radius: 10px;
                                border-bottom-left-radius: 10px;color: black; top:14px" align="center">
                                    <p style="font-size: .9em;margin-top: 4px;padding: 0px 2px;">{{ $t("Customer.Home.You_can_join_the_call_10_min_before_the_scheduled_time") }}</p>
                              </div>
                            </v-row>
                          </v-card>
                        </v-row>
                      </v-sheet>
                    </v-carousel-item>
                  </v-carousel>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <v-card flat class="HomeCardClass" style="width:90%; height: 90%; border-radius: 8px;margin-top: 20px;padding:10px;">
              <div style="display:flex; flex-direction:row;justify-content: space-around; margin-top:15px " class="loaderAnimation" >
                <div>
                  <v-skeleton-loader
                    class="mx-auto"
                    max-width="300"
                    type="avatar"
                  ></v-skeleton-loader>
                </div>
                <div>
                  <v-skeleton-loader
                    class="mx-auto"
                    width="150px"
                    type="text"
                    style="margin-top:25px"
                  ></v-skeleton-loader>
                </div>
                <div>
                  <v-skeleton-loader
                    class="mx-auto"
                    max-width="300"
                    style="margin-top:10px"
                    type="button"
                  ></v-skeleton-loader>
                </div>
              </div>
              <div style="display:flex; flex-direction:row;justify-content: space-evenly; ">
                <div>
                  <v-skeleton-loader
                    class="mx-auto"
                    width="150px"
                    type="list-item-two-line"
                  ></v-skeleton-loader>
                </div>
                <div>
                  <v-skeleton-loader
                    class="mx-auto"
                    width="150px"
                    type="list-item-two-line"
                  ></v-skeleton-loader>
                </div>
              </div>
            </v-card>
          </div>
          <div  class="bookDocClass" v-show="!isLoadingAppointment">
            <v-card flat style="margin:10px;" align="center">
              <div style="margin-top: 10px; ">
                <v-row no-gutters>
                  <v-col>
                    <div class="bookDoc">
                      <div class="flexCard">
                        <img src="https://d3vsnl8idgwrlw.cloudfront.net/book_a_doc_icon.svg"  style="margin-right:16px">
                        <h3>{{ $t("Customer.Home.Doctor_Consultation") }}</h3>
                      </div>
                      <img @click="redirectDoctorConsultationInfo" src="https://d3vsnl8idgwrlw.cloudfront.net/info.svg"/>
                    </div>
                  </v-col>
                </v-row>
                <v-btn
                  class="bookDocClassbtn"
                  depressed
                  color="primary"
                  style="color:white;font-weight: 900;width:90%"
                  @click="redirectToBookAppointment()"
                  >
                  <div style="display:flex; flex-direction:row; justify-content:space-evenly; vertical-align:middle;">
                    <img src="https://d3vsnl8idgwrlw.cloudfront.net/join_call.svg" width="18px" style="margin-right:10px">
                    <h4 style="color: white; text-decoration: none; text-align:center">{{ $t("Customer.Home.Make_an_Appointment") }}</h4>
                  </div>
                </v-btn>
              </div>
              <br>
            </v-card>

            <!-- <v-card style="box-shadow: 0px 8px 20px rgba(5, 71, 137, 0.1) !important; margin:10px;"
            align="center">
            <div v-if="walkinDoctorFlag" class="WalkinMainDivClass">
              <div class="flexCard1">
                <div>
                  <img src="https://d3vsnl8idgwrlw.cloudfront.net/quickConsultation.png"  style="margin-right:16px">
                </div>
                <div>
                  <h3 style="word-wrap: break-word ">{{ $t("Customer.Home.Quick_Consultation") }}</h3>
                </div>
                <div style="display: flex; align-items: center;margin: 0px 9px 0px 8px !important;padding: 0px 12px 0px 8px !important;background: #E4F5FF;border-radius: 4px;height:30px">
                  <div class="ViewMoreClass" @click="redirectToViewAllDoctors">{{ $t("Customer.Home.View_More") }}</div>
                  <div style="padding-left: 5px;">
                    <img src="https://d3vsnl8idgwrlw.cloudfront.net/arrow_color.svg" />
                  </div>
                </div>

                </div>
              <div align="left" class="WalkinTopTextClass">
                <span>{{ $t("Customer.Home.Walkin_Top_Text") }}</span>
              </div>
              <v-slide-group v-model="doctorDetails" class="WalkindoctorListClass" center-active  >
                <v-slide-item
                  v-for="doctor in doctor_list"
                  :key="doctor.sama_id"
                  v-slot="{ active, toggle }"
                  style="margin: 2px 5px !important;"
                  >
                  <v-card
                    active-class="active"
                    elevation="2"
                    height="150"
                    width="158"
                    justify="center"
                    :class="active ? 'activeClass' : 'defaultClass'"
                    @click="toggle(), getDoctorDetails(doctor)"
                    style="margin-right:8px;border: 1px solid #C9E5FF;border-radius: 8px;"
                    @change="dateRange = []"
                    >
                    <div v-if="!doctor.last_slot">
                      <v-avatar style="margin-top: 10%;" size="70">
                        <img v-bind:src="doctor.doctor_profile_picture" alt="Image"/>
                      </v-avatar>
                      <div>
                        <h3 style="padding-top: 6%; font-size: 0.9em">
                          {{ doctor.doctor_name }}
                        </h3>
                      </div>
                      <div style="justify-content: center; padding-top: 3%">
                        <p
                          style="
                            font-weight: 300;
                            padding: 0px 5px;
                            font-size: 0.8em;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          "
                          >
                          {{ doctor.service_fee + doctor.commission > 0 ? doctor.service_fee + doctor.commission : doctor.service_fee }} {{ $t("Customer.Home.MMK") }}
                        </p>
                      </div>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                        >
                      </v-row>
                    </div>
                    <div v-else-if="$i18n.locale == 'en'" style="display:flex;flex-direction: column; align-items: center;margin-top:30%">
                      <h4 style="display:flex; align-items: center;padding:0px !important"> View </h4>
                      <h4 style="display:flex; align-items: center;"> More </h4>
                    </div>
                    <div v-else style="display:flex;flex-direction: column; align-items: center;margin-top:35%; ">
                      <h4 style="display:flex; align-items: center;padding:0px !important"> {{ $t("Customer.Home.View_More") }} </h4>
                    </div>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </div>
            </v-card> -->

            <v-card flat style="margin:10px;"
              align="center" v-if="doctor_list.length !=  0"
              >
              <div style="margin-top: 10px; ">
                <v-row no-gutters>
                  <v-col>
                    <div class="bookDoc">
                      <div class="flexCard">
                        <div>
                        <img src="https://d3vsnl8idgwrlw.cloudfront.net/quickConsultationIcon.svg"  style="margin-right:16px"></div>
                        <div>
                        <h3 style="text-align: initial;">{{ $t("Customer.Home.Quick_Consultation") }}</h3></div>
                      </div>
                      <div style="display: flex; align-items: center;margin: 0px 9px 0px 8px !important;padding: 0px 12px 0px 8px !important;background: #E4F5FF;border-radius: 4px;height:30px">
                        <div class="ViewMoreClass" @click="redirectToViewAllDoctors">{{ $t("Customer.Home.View_More") }}</div>
                        <div style="padding-left: 5px;">
                          <img src="https://d3vsnl8idgwrlw.cloudfront.net/arrow_color.svg" />
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <div align="left" class="WalkinTopTextClass">
                  <span>{{ $t("Customer.Home.Walkin_Top_Text") }}</span>
                </div>
                <v-slide-group v-model="doctorDetails" class="WalkindoctorListClass" center-active  >
                  <v-slide-item
                    v-for="doctor in doctor_list"
                    :key="doctor.sama_id"
                    v-slot="{ active, toggle }"
                    style="margin: 2px 5px !important;"
                    >
                    <v-card
                      active-class="active"
                      elevation="2"
                      height="185"
                      width="158"
                      justify="center"
                      :class="active ? 'activeClass' : 'defaultClass'"
                      @click="toggle(), getDoctorDetails(doctor)"
                      style="margin-right:8px;border: 1px solid #C9E5FF;border-radius: 8px;"
                      @change="dateRange = []"
                      >
                      <div v-if="!doctor.last_slot">
                        <v-avatar style="margin-top: 10%;" size="70">
                          <img v-bind:src="doctor.doctor_profile_picture" alt="Image"/>
                        </v-avatar>
                        <div>
                          <h3 style="padding-top: 6%; font-size: 0.9em">
                            {{ doctor.doctor_name }}
                          </h3>
                        </div>
                        <div style="justify-content: center; padding-top: 3%">
                          <p
                            style="font-weight:300;
                              padding: 0px 5px;
                              font-size: 0.8em;
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            "
                            >
                            {{ doctor.fee }} {{ $t("Customer.Home.MMK") }}
                          </p>
                        </div>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center"
                          >
                        </v-row>
                      </div>
                      <div v-else-if="$i18n.locale == 'en'" style="display:flex;flex-direction: column; align-items: center;margin-top:30%">
                        <h4 style="display:flex; align-items: center;padding:0px !important"> View </h4>
                        <h4 style="display:flex; align-items: center;"> More </h4>
                      </div>
                      <div v-else style="display:flex;flex-direction: column; align-items: center;margin-top:35%; ">
                        <h4 style="display:flex; align-items: center;padding:0px !important"> {{ $t("Customer.Home.View_More") }} </h4>
                      </div>
                    </v-card>
                  </v-slide-item>
                </v-slide-group>
              </div>
              <br>
            </v-card>

            <!-- <img @click="redirectToSpecDocs" class="imgTag" src="@/assets/sexwellnessBanner.png"> -->

            <v-card flat style="margin:10px 10px 0px 10px; padding:5px;">
              <v-carousel
                cycle
                height="170"
                width="auto"
                class="bannerStyle"
                :show-arrows="false"
              >
                <v-carousel-item

                  v-for="(slide, i) in specSlides"
                  :key="i"
                  @click="specDoctors(slide._id)"
                >

                  <v-img style="height:auto; width:100%; border-radius:5px !important;" :src="slide.imageUrl"></v-img>

                </v-carousel-item>
              </v-carousel>
            </v-card>

            <v-card flat style="margin:10px;" align="center">
              <div style="margin-top: 0px;">
                <div class="bookDoc">
                  <div class="flexCard">
                    <img src="https://d3vsnl8idgwrlw.cloudfront.net/cc_icon.svg"  style="margin-right:16px">
                    <h3>{{ $t("Customer.Home.Care_Companion") }}</h3>
                  </div>
                  <img @click="redirectCareComapnionInfo" src="https://d3vsnl8idgwrlw.cloudfront.net/info.svg"/>
                </div>
                <div class="callButton">
                  <v-btn
                    class="bookDocClassbtn"
                    depressed
                    color="primary"
                    style="color:white;font-weight: 900;width:90%"
                    @click="redirectToCareCompanion()"
                    >
                    <div style="display:flex; flex-direction:row; justify-content:space-evenly; vertical-align:middle;">
                      <img src="https://d3vsnl8idgwrlw.cloudfront.net/phone_cc.svg" width="18px" style="margin-right:10px">
                      <h4 style="color: white; text-decoration: none; text-align:center">{{ $t("Customer.Home.Request_Call") }}</h4>
                    </div>
                  </v-btn>
                </div>
              </div>
              <br>
            </v-card>
          </div>
        </div>
        <bottomnavbar style="position:sticky; bottom:0px; width: 100%;margin-bottom:1vh" :pageName=pageName :isLoggedIn=currentCustomer :kbzEmployee=kbzEmployee></bottomnavbar>
      </v-app>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import * as h337 from 'heatmap.js';
import HorizontalScroll from 'vue-horizontal-scroll';
import banner from './banner.vue';
import bottomnavbar from './bottomnavbar.vue';
import 'vue-horizontal-scroll/dist/vue-horizontal-scroll.css';
import axios from "axios";
import NavBar from '../../../views/navbar.vue';
import DateRendered from './dateRender.vue';
import TimeRender from './TimeRender.vue';
import FacebookCustomerChatComponent from '../../../components/facebookMessengerModal.vue'
import TermsAndConditionsVue from './TermsAndConditions.vue';

export default {
  name: 'CustomerHome',
  components: {
    banner,
    bottomnavbar,
    HorizontalScroll,
    NavBar,
    DateRendered,
    TimeRender,
    FacebookCustomerChatComponent
  },
  data() {
    return {
      appointment_list:[],
      specSlides:[],
      //specSlides:['https://img.freepik.com/free-psd/dentist-dental-care-social-media-banner-template_120329-1320.jpg','https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/testedit'],
      pageName : "HomePage",
      customer_id : "",
      customer_name : "",
      error_enable : false,
      error_alert: false,
      auth_error_enable : false,
      currentCustomer:"",
      isLinkActive: false,
      ismobile : false,
      isLoadingAppointment:true,
      isLoggedIn : false,
      kbzEmployee: false,
      verifiedStatus:'',
      videoCallTestModal: false,
      userConfigId:'',
      testStatus:false,
      urlparams: "",
      new_user: false,
      url_string: "",
      tcConsentModal:false,
      tcStatusFlag:"",
      tcConsentAssistModal: false,
      tcConsentChangeModal: false,
      trust_level: "",
      version: "",
      trust_alert: false,
      doctor_list:[],
      doctorDetails:"",
      walkinDoctorFlag:true,
      appLevelCheckModal:false,
      s3Trans: "",
      region_block: false,
      isApp:false,
      tcConcentBtn:false,
      // heatMapFlag: null,
      dataPoint: [],
    };
  },
  methods: {
    drawHeatMap(){
      let width = window.innerWidth;
      let height = window.innerHeight
      var holder = d3.select('#heatMapWrapper').append('svg')
      var getAllLogsFromDBBody = {
        token: this.currentCustomer,
        pageName: 'HomePage'
      };
      axios.post(process.env.VUE_APP_BACKEND_URL+"/getAllLogsFromDB", getAllLogsFromDBBody)
      .then((getAllLogsFromDBResponse)=>{
        console.log(getAllLogsFromDBResponse.data.data)
        let dataPoint = getAllLogsFromDBResponse.data.data
        let maxValue =  Math.max.apply(Math, dataPoint.map(function(o) { return o.value; }))
        let minValue =  Math.min.apply(Math, dataPoint.map(function(o) { return o.value; }))
        var heatMapConfig = h337.create({
          container: document.getElementById('heatMapWrapper'),
          radius: 33,
          blur: .95
        })
        let sampleData = {
          max: maxValue,
          min: minValue,
          data: dataPoint
        }
        console.log('sampleData', sampleData)
        heatMapConfig.setData(sampleData)
      })
      .catch((error)=>{
        console.log("Error")
      })
    },
    redirectToSpecDocs()
    {
      this.$router.push({
        name: "ListofSpecDoctors"
      });
    },
    specDoctors(specId)
    {
      this.$router.push({
        name: 'ListofSpecDoctors', params: { id: specId }
      })
    },
    changeLanguage(language, currentCustomer){
      //axios call
      this.isLoading = true
      var language_body = {
        "language": language,
        "token" : currentCustomer,
        "typeOfUser" : "CUSTOMER"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL+"/updateLanguage",language_body)
      .then((changeLanguageResponse) =>{
        this.$i18n.locale = language;
        this.$store.dispatch("changeLocale", this.$i18n.locale);

      })
      .catch((changeLanguageError) =>{
         this.isLoading = false;
        if(changeLanguageError.response.status == 401){
            this.auth_error_enable = true;
          }
          else{
            this.error_enable = true;
          }
      });
      //this.$router.go();
    },
    closeMyMedicine(){
      kbzpay.gotoFunction('native://kbz/customer/homepage/wallet');
    },
    closeAppLevelCheckModal(){
      this.appLevelCheckModal=false
      kbzpay.gotoFunction('native://kbz/customer/about')
    },
    redirectToViewAllDoctors()
    {
      this.$router.push({
        name: "ListOfWalkinDoctor",
      });
    },
    getDoctorDetails(doctorDetails){
      if(this.isLoggedIn)
      {
        if(doctorDetails.last_slot){
          this.$router.push({
            name: "ListOfWalkinDoctor",
          });
        }
        else{
          let doctor_id = doctorDetails._id
          this.$router.push({
            path: "/customer/walkinBookAppointment/" + doctor_id
          });
        }
      }
      else
      {
        if(!doctorDetails.last_slot)
        {
          this.$router.push({
            name: "Login"
          });
        }
        else
        {
          this.$router.push({
            name: "ListOfWalkinDoctor",
          });
        }
      }
    },
    redirectToKBZPayUpgrade(){
      kbzpay.gotoFunction('native://kbz/customer/upgrade')
    },
    redirectToTermsAndCondition(){
      this.$router.push({
        name: "TermsAndConditions",
      });
    },
    closeTcConsentChanged(){
      this.tcStatusFlag = "Denied";
      this.tcConsentChangeModal = false;
      var changeConsentBody = {
        'consentStatus' : 'Denied',
        'token' : this.currentCustomer,
        'typeOfUser' : "CUSTOMER"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL+"/changeConsentStatus", changeConsentBody)
      .then((changeSuccess) => {

      })
      .catch((changeError) => {
        console.log(changeError);
        this.error_enable = true;
      });
    },
    ChangedTCAccepted(){
      var changeConsentBody = {
        'consentStatus' : 'Accepted',
        'token' : this.currentCustomer,
        'typeOfUser' : "CUSTOMER"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL+"/changeConsentStatus", changeConsentBody)
      .then((changeSuccess) => {
        this.tcConsentChangeModal = false;
      })
      .catch((changeError) => {
        this.error_enable = true;
      });
      if(this.appointment_list.length > 0)
      {
        if(!this.verifiedStatus){
        // console.log(this.verifiedStatus);
        if(!this.testStatus){
          if(!this.$cookies.get("alerted")){
            this.videoCallTestModal = true
            this.$cookies.set("alerted", true, "1h")
          }
        }
        }
      }
    },
    closeTcConsentAssist(){
      this.tcConsentAssistModal = false;
      this.tcConsentModal =true
    },
    tcAccepted(){
      this.tcConcentBtn = true
      var changeConsentBody = {
        'consentStatus' : 'Accepted',
        'token' : this.currentCustomer,
        'typeOfUser' : "CUSTOMER"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL+"/changeConsentStatus", changeConsentBody)
      .then((changeSuccess) => {

        this.tcConsentModal = false;
        this.tcConcentBtn = false
      })
      .catch((changeError) => {
        this.error_enable = true;
        this.tcConcentBtn = false
      });
      if(this.appointment_list.length > 0)
      {
        if(!this.verifiedStatus){
          // console.log(this.verifiedStatus);
          if(!this.testStatus){
            if(!this.$cookies.get("alerted")){
              this.videoCallTestModal = true
              this.$cookies.set("alerted", true, "1h")
            }
          }
        }
      }
    },
    closeTcConsent(){
      this.tcStatusFlag = "Denied";
      this.tcConsentAssistModal = true;
      this.tcConsentModal = false;
      var changeConsentBody = {
        'consentStatus' : 'Denied',
        'token' : this.currentCustomer,
        'typeOfUser' : "CUSTOMER"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL+"/changeConsentStatus", changeConsentBody)
      .then((changeSuccess) => {

      })
      .catch((changeError) => {
        console.log(changeError);
        this.error_enable = true;
      });
    },
    closeDialog(){
      this.videoCallTestModal = false;
      //  var updateUserConfigData = {
      //           token: this.currentCustomer,
      //           userConfigId: this.userConfigId,
      //           testStatus: "true",
      //           assistanceRequired: "true",
      //           verified_status:"false"
      //         };
      //         axios
      //           .post(process.env.VUE_APP_BACKEND_URL + "/updateUserConfig", updateUserConfigData)
      //           .then((updateUserConfigResponse) => {
      //             console.log("[Success]");
      //           })
      //           .catch((updateUserConfigError) => {
      //           });
    },
    redirectDoctorConsultationInfo(){
       this.$router.push({
        name: "DoctorConsultationInfo",
      });
    },
    redirectCareComapnionInfo(){
      this.$router.push({
        name: "CareCompanionInfo",
      });
    },
    closeDialogUnauthorized(){
    this.auth_error_enable = false;
    this.$router.push({
        name: "Login",
      });
    },
    closeDialogError(){
      this.error_enable = false;
    },
    closeDialogErrorAlert(){
      this.error_alert = false;
    },
    BookAppointment() {
      this.$router.push({
        name: "BookAppointment",
      });
    },
    showAppointment(index) {
      this.$router.push({
        name: "ViewAppointment",
        params: {"book_id": index,"success_flag": 0}
      })
    },
    redirectToViewAppointment(bookingId)
    {
      this.$router.push({
        path: '/customer/viewAppointment/'+bookingId
      });
    },
    redirectToVideoCallTestPage(){
       this.$router.push({
        name: "VideoCallTestPage",
      });
    },
    redirectToCareCompanion()
    {
      if(this.currentCustomer){
        this.$router.push({
        name: "careCompanion"
      })
      }
      else{
        this.$router.push({
					name : "Login"
				})
      }
    },
    redirectToBookAppointment()
    {
      if(this.currentCustomer){
        this.$router.push({
          name: "BookAppointment"
        })
      }
      else{
        this.$router.push({
					name : "Login"
				})
      }
    },
    redirectToCallpage(meetingId)
    {
      let userAgent = navigator.userAgent
      if(userAgent.includes("kbzpay")){
      var slicedData = userAgent.slice(userAgent.indexOf("kbzpay")).split('/')
      if(slicedData[slicedData.length-1] < "5.2.3"){
       //this.appLevelCheck = false
       this.appLevelCheckModal = true
      }
      else{
         this.$router.push({
       path: '/customer/videocallTwilio/'+ meetingId
     })
      }
    }
    else{
      this.$router.push({
       path: '/customer/videocallTwilio/'+ meetingId
     })
    }
    },
    onClick(e){
      if( this.currentCustomer === null){
        var createClickLogBody = {
          clientX: String(e.clientX),
          clientY: String(e.clientY),
          pageName: 'HomePage'
        }
      } else {
        var createClickLogBody = {
          token: this.currentCustomer,
          typeOfUser: 'CUSTOMER',
          clientX: String(e.clientX),
          clientY: String(e.clientY),
          pageName: 'HomePage'
        }
      }
        axios.post(process.env.VUE_APP_BACKEND_URL+"/createClickLog",createClickLogBody)
        .catch((createClickLogError) => {
          console.log('[ERROR] in create click log TimeStream', createClickLogError);
        });
    },
  },
  mounted() {
    this.ismobile = this.$device.mobile // true
    // console.log("isMobile", this.ismobile)
    // document.title = "Home"
    if(this.$route.query.lang && ["en","my"].includes(this.$route.query.lang))
    {
      this.$i18n.locale = this.$route.query.lang == "my" ? "mm" : this.$route.query.lang;
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    }
    if(this.$store.state.locale !== "")
    {
      this.$i18n.locale =  this.$store.state.locale;
    }
    else
    {
      this.$i18n.locale = "mm";
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    }
    if(window.xm){
      document.title = '';
    } else {
    if(this.$store.state.locale == "en")
      document.title = "Home"
    else
      document.title = "ပင်မ"
    }
    if(this.$store.state.name !== "")
    {
      this.customer_name = this.$store.state.name;
    }
    if(this.$store.state.employee !== "")
    {
      this.kbzEmployee = this.$store.state.employee;
    }

    this.currentCustomer = this.$cookies.get("customerToken");
    this.urlparams = this.$route.query.kbzpay_token;
    // if(this.urlparams)
    // {
    //   this.currentCustomer = "";
    // }
        // setTimeout(() =>{ this.heatMapFlag = true; console.log('Hello')}, 1000)
    if(this.urlparams || this.currentCustomer)
    {
      var customerHomeData = {
        loginToken: !this.currentCustomer ? '' : this.currentCustomer,
        kbzAppToken: !this.urlparams ? '' : this.urlparams
      };
      axios.post(process.env.VUE_APP_BACKEND_URL + "/customerHomeScreenInAppTwilio", customerHomeData)
      .then((customerHomeScreenInAppResponse) =>{
        this.$cookies.set("customerToken", customerHomeScreenInAppResponse.data.token,"1w");
        this.currentCustomer = this.$cookies.get("customerToken");
        // console.log("customerHomeScreenInAppTokBox",customerHomeScreenInAppResponse.data.walkIn_doctors_data)
        localStorage.removeItem('fileStatus')
        this.new_user = customerHomeScreenInAppResponse.data.new_customer_flag;
        // this.customer_id = customerHomeScreenInAppResponse.data.customer._id;
        this.customer_name = customerHomeScreenInAppResponse.data.customer.customer_name;
        this.specSlides = customerHomeScreenInAppResponse.data.spec_details;
        this.appointment_list = customerHomeScreenInAppResponse.data.data;
        // this.language = customerHomeScreenInAppResponse.data.customer.language;
        this.kbzEmployee = customerHomeScreenInAppResponse.data.customer.kbzEmployee;
        // this.verifiedStatus = customerHomeScreenInAppResponse.data.user_config_data.verified_status;
        // this.userConfigId = customerHomeScreenInAppResponse.data.user_config_data._id;
        // this.testStatus = customerHomeScreenInAppResponse.data.user_config_data.test_status;
        this.trust_level = customerHomeScreenInAppResponse.data.trust_flag;
        this.tcStatusFlag = customerHomeScreenInAppResponse.data.customer.consent_acceptance;
        this.doctor_list = customerHomeScreenInAppResponse.data.walkIn_doctors_data;
        this.s3Trans = customerHomeScreenInAppResponse.data.walkIn_doctors_data_mm;
        this.isLoggedIn = true;
        let showHeatMap = customerHomeScreenInAppResponse.data.customer.showHeatMap
        if(showHeatMap){
          localStorage.setItem('showHeatMap', true)
          this.drawHeatMap();
        } else {
          localStorage.removeItem('showHeatMap')
        }
        if(this.doctor_list.length != 0){
          if(this.$i18n.locale == 'mm')
          {
            this.doctor_list.forEach((doctor) =>{
              doctor.doctor_name = this.s3Trans[doctor.doctor_id]['doctor_name'];
            });
          }
          this.walkinDoctorFlag = true
          this.doctor_list.push({last_slot: true})
        }
        else
        {
          this.walkinDoctorFlag = false;
        }
        var userAgent = navigator.userAgent;
        // var userAgent = "Mozilla/5.0 (Linux; Android 9; MI 6X Build/PKQ1.180904.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/76.0.3809.111 Mobile Safari/537.36; kbzpay app/customer platform/android lang/my unicode/true version/5.0.3"
        if(userAgent.includes("kbzpay")){
          this.$store.dispatch("changeUserType", "APP");
          this.isApp = true;
        }
        else
        {
          this.$store.dispatch("changeUserType", "MOBILE");
        }
        if(this.trust_level == "ELIGIBLE")
        {
          //Dont show any popup for consent

          // if((this.tcStatusFlag == "No" || !this.tcStatusFlag) && this.customer_name != "Guest"){
          //   this.tcConsentModal = true
          // }
          // if( this.tcStatusFlag == "Denied" && this.customer_name != "Guest"){
          //   this.tcConsentAssistModal = true
          // }
          // if( this.tcStatusFlag == "Changed" && this.customer_name != "Guest"){
          //   this.tcConsentChangeModal = true
          // }
          this.$cookies.set("verifiedStatus", this.verifiedStatus);
          if(this.urlparams) // only set new token if its from app
          {
            // this.$cookies.set("customerToken", customerHomeScreenInAppResponse.data.token,"1w");
            // this.currentCustomer = this.$cookies.get("customerToken");
            if(customerHomeScreenInAppResponse.data.customer.language != this.$i18n.locale && this.$route.query.lang && ["en","my"].includes(this.$route.query.lang))
              this.changeLanguage(this.$i18n.locale, this.currentCustomer);
          }
          else
          {
             if(customerHomeScreenInAppResponse.data.customer.language != this.$i18n.locale)
             {
              this.$i18n.locale = customerHomeScreenInAppResponse.data.customer.language;
              this.$store.dispatch("changeLocale", this.$i18n.locale);
            }

          }
          //logging page hit - start
          if(this.currentCustomer)
          {
            let x =  localStorage.getItem("currentURL")
            let pageName = "CustomerHomeScreen"
            if( x == pageName)
              x = "";
            localStorage.setItem("currentURL", pageName)
            var pageAccessBody = {
              token: this.currentCustomer,
              pageName: pageName,
              previousPage: x ? x : "START"
            }
            axios.post(process.env.VUE_APP_BACKEND_URL + "/logUserAPIInvocations", pageAccessBody)
            .then((logSuccess) =>{

            })
            .catch((logError) =>{

            });
          }
          //logging page hit - end
          if(this.$store.state.locale != this.$i18n.locale)
          {
            this.$store.dispatch("changeLocale", this.$i18n.locale);
          }
          if(this.$store.state.name != this.customer_name)
          {
            this.$store.dispatch("changeName", this.customer_name);
            this.$store.dispatch("changeEmployee", this.kbzEmployee);
          }
          if(this.appointment_list.length > 0)
          {
            this.appointment_list.forEach((x) => {
              //2021-09-09 20:00:00 GMT+0630
              var dateNow = new Date();
                 var get_diff = new Date(x.booking_epoch_time).getTime() - dateNow.getTime()
                  var end_diff = new Date((x.booking_closing_epoch_time)) - dateNow.getTime()
             x.isLinkActive = (get_diff < 600000 && end_diff > 0) ? true : false;
            });
            if(!this.verifiedStatus && !this.testStatus && this.tcStatusFlag == "Accepted" && !this.$cookies.get("alerted") && this.$store.state.user_type == 'MOBILE'){
              this.videoCallTestModal = true
              this.$cookies.set("alerted", true, "1h");
            }
          }
          this.isLoadingAppointment= false;
        }
        else
        {
          this.trust_alert = true;
        }
      })
      .catch((customerHomeScreenInAppError) =>{
        console.log(customerHomeScreenInAppError);
        if(customerHomeScreenInAppError.response.status == 401){
          this.auth_error_enable = true;
        }
        else if(customerHomeScreenInAppError.response.status == 406)
        {
          // alert with "Something went wrong, please restart app"
          this.error_alert = true;
        }
        else if(customerHomeScreenInAppError.response.status == 400)
        {
          var userAgent = navigator.userAgent;
          // alert with "Something went wrong"
          if(window.xm || userAgent.includes("kbzpay"))
            this.error_alert = true;
          else
            this.error_enable = true;
        }
        else if(customerHomeScreenInAppError.response.status == 408)
        {
          this.region_block = true;
        }
        else{
          this.error_enable = true;
        }
      });
    }
    else
    {
      //clean local storage
      this.$store.dispatch("cleanVuex");
      this.customer_name = "";
      this.kbzEmployee = false;
      this.isLoggedIn = false;
      axios.post(process.env.VUE_APP_BACKEND_URL + "/getHomePageDoctors")
      .then((HomePageResponse) =>{
        this.doctor_list = HomePageResponse.data.doctorsData;
        this.specSlides = HomePageResponse.data.spec_details;
        this.s3Trans = HomePageResponse.data.mm;
        if(this.doctor_list.length != 0){
          if(this.$i18n.locale == 'mm')
          {
            this.doctor_list.forEach((doctor) =>{
              doctor.doctor_name = this.s3Trans[doctor.doctor_id]['doctor_name'];
            });
          }
          this.walkinDoctorFlag = true;
          this.doctor_list.push({last_slot: true})
        }
        else
        {
          this.walkinDoctorFlag = false;
        }
        this.isLoadingAppointment= false;
      })
      .catch((HomePageError) => {
        console.log('Error', HomePageError), 
        this.isLoadingAppointment = false;
        this.walkinDoctorFlag = false;
      });
    }
    
  },
}
</script>


<style>
.regionLockClass {
  font-size: 12pt;
}
.WalkindoctorListClass {
  padding: 0px 10px;
}
.WalkinTopTextClass {
  margin:  8px 5px 5px 8px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4F4F4F;
  padding: 0px 10px;
}
.WalkinBottomTextClass {
  margin:  8px 5px 5px 8px !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  color: #333333;
  padding: 0px 10px;
}
.WalkinMainDivClass {
  /* background: linear-gradient(180deg, #1467BF 50%, white 50%); */
  padding-bottom: 30px;
  margin: 0px -6px;
}
.WalkinDivClass {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
}
.ViewMoreClass {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1467BF;
  padding: 4px 4px 4px 8px;
}
.WalkinTextClass {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color:#333333;
  text-align:left;
  margin: 8px 5px 5px 8px !important;
}
::-webkit-scrollbar {
  width: 2px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f5faff !important;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #f5faff !important;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f5faff !important;
}
.v-application--wrap
{
  min-height:  0 !important;
}
.spacing{
  margin-top: 10px;
}
.upcoming{
  margin-top: 16px;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content : space-evenly;
  padding: 16px !important;
}
.bannerImg{
 padding:0px !important;
 border-radius: 8px !important;
 border: 10px #000;
}

.bookDoc{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 16px;
}

.card-footer-home{
  position: relative;
  align-content:center;
  background-color: #fecdbe;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  text-align: center;
  width: 90vw;
  height: 4vh;
  font-size: 9pt;
}
.flexCard{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.flexCard1{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 14px;
  padding-top:16px
}

.horizontal-scroll {
  display: flex;
  width: 100%;
  height: 30%;
  padding: 10px;
}
.outer {
  display: flex;
  flex: 1;
  width: auto;
  height: 100%;
  padding: 0 20px;
  flex-flow: row nowrap;
  align-items: center;
}
.inner-content {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: calc(100%-40px);
  height: 100%;
  border: solid 1px #2c3e50;
  border-radius: 5px;
}
.inner-content:not(:first-of-type) {
  margin-left: 30px;
}
div.v-window .v-item-group .theme--dark .v-carousel{
  height: 215px !important;
}
.mainBtnClass{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
}
.btnClass{
  background-color: grey;
}
.MainDiv {
  min-height:  0 !important;
}
.activeClass {
  background-color: #1467BF !important;
  color: white !important;
  padding: 0px !important;
  margin: 0px !important;
  box-shadow: 0px 0px 28px 0px #1366be4d !important;
}
.v-btn__content{
  line-height: 21px !important;
}
/* .loaderAnimation{
    background-image:
     linear-gradient(
        90deg,
        rgba(lightgrey, 0) 0,
        rgba(lightgrey, .8) 50%,
        rgba(lightgrey, 0) 100%
      ),
} */
/* @media (min-width: 800px){
  .Maindiv{
   width: 800px;
   margin-left: auto !important ;
   margin-right: auto !important;

  }
.spacing{
    height: 190px !important;
    margin-bottom: 10%;
  }
  .HomeCardClass{
    width: 80% !important;
  }
  .homeUserPngClass{
    padding-left: 30px !important;
  }
.homeNameCardCalss{
  margin-top:16px !important;
  margin-left: 0px !important;
}
.homeBtnClass{
  height: 60px !important;
}
.btnHomeClass{
  width: 150px !important;
}
.timeClass{
  float: right;
  margin-left: 45%;
}
.dateClass{
  margin-left: 5% !important;
}
.dateClass1{
  padding-left: 30px;
}
.notificationBoxClass{
  height: 30px !important;
  width: 650px !important;
}
.upcomingClass{
  margin-left: 8% !important;
  margin-top: 1% !important;
}
.bookDocClass{
  width: 85%;
  margin-top: 10% ;
}
.bookDocClassbtn{
  width: 65% !important;
}
.bannerClass{
  height: 40%;
  width: 100% !important;
}
} */
</style>
<style scoped>

.bannerStyle{
  border-radius: 5px;
}

.tcconcentClass.v-btn__content{
  margin-top: 5px !important
}
.imgTag{

  border-radius: 8px;
}

</style>

<style>


.bannerStyle .v-carousel__controls{
  background: unset !important;
  padding-top: 20px;
  z-index: 0 !important;
}

.bannerStyle .v-carousel__controls__item.v-btn {
  font-size: 5px !important;
  color: #C9E5FF !important;
  opacity: unset !important;
  padding: 10px;
}

.bannerStyle .v-carousel__controls__item .v-icon {
  opacity: unset !important;
  padding: 10px;
}
.bannerStyle .v-btn--icon.v-size--small {
  height: 0px;
  width: 0px;
  padding: unset !important;
}

.bannerStyle .v-carousel__controls__item.v-btn.v-btn--active {
  font-size: 5px !important;
  color:#EB5757 !important;
  opacity: unset !important;
}



</style>